import { config } from 'config'
import { FeatureFlags, featureFlags } from 'modules/featureFlags'
import { isMobileDevice } from 'utils/deviceDetection'

const REDIRECT_MAP: {
  [s in FeatureFlags['signupRedirectTo']]: string
} = {
  GENERATE: '/generate',
  HOME: '/',
  CREATE: '/create',
}

export const getLoginBroadcastChannel = () => {
  return new BroadcastChannel('gamma_app_login')
}

export const getDefaultSignupRedirect = () => {
  const openAiStatus = featureFlags.get('openAiStatus')
  const isOpenAiStatusDown = openAiStatus === 'DOWN'

  if (isOpenAiStatusDown) {
    return '/'
  }

  const redirectTo = featureFlags.get('signupRedirectTo')
  if (redirectTo === 'GENERATE') {
    return '/create/generate?ref=home'
  } else if (redirectTo === 'CREATE') {
    return isMobileDevice() ? '/create/generate?ref=home' : '/create'
  } else if (redirectTo) {
    return REDIRECT_MAP[redirectTo]
  } else {
    return '/'
  }
}

export const fetchHMAC = async (provider: 'churnkey' | 'intercom') => {
  const hmac = await fetch(`${config.API_HOST}/hmac/generate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ provider }),
  })
    .then((res) => res.text())
    .catch((err) => {
      console.warn(`[fetchHMAC] provider:${provider} error`, err)
    })

  return hmac || undefined
}

export const fetchJWT = async (provider: 'zendesk') => {
  const jwt = await fetch(`${config.API_HOST}/jwt/generate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ provider }),
  })
    .then((res) => res.text())
    .catch((err) => {
      console.warn(`[fetchJWT] provider:${provider} error`, err)
    })

  return jwt || undefined
}
