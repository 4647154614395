import { createStandaloneToast } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { UppyFile } from '@uppy/core'
import { uniq } from 'lodash'

import { config } from 'config'
import { featureFlags } from 'modules/featureFlags'
import { isSVG } from 'modules/tiptap_editor/extensions/media/utils'

const { toast } = createStandaloneToast()

// 20 megapixels
const IMGPROXY_MAX_MEGAPIXELS = 20
const IMGPROXY_MAX_PIXELS = IMGPROXY_MAX_MEGAPIXELS * 1000000
const IMGPROXY_MAX_HEIGHT_PIXELS = 4000
const IMGPROXY_MAX_WIDTH_PIXELS = 5000

const GAMMA_CDN_HOSTNAME_PROD = 'https://cdn.gamma.app'
const GAMMA_CDN_HOSTNAME_STAGING = 'https://cdn-staging.gamma.app'
const GAMMA_CDN_HOSTNAMES = [
  GAMMA_CDN_HOSTNAME_PROD,
  GAMMA_CDN_HOSTNAME_STAGING,
]
const UNSPLASH_HOST = 'https://images.unsplash.com/'
const GIPHY_HOSTS = ['https://media.giphy.com/', 'https://media0.giphy.com/']

export const isGammaCDNUrl = (url?: string) => {
  return (
    url &&
    GAMMA_CDN_HOSTNAMES.some((hostname) => url.startsWith(hostname + '/'))
  )
}

export const isGammaIconUrl = (url: string) => {
  if (!isGammaCDNUrl(url)) return false

  try {
    const urlObj = new URL(url)
    const path = urlObj.pathname
    return path.startsWith('/_app_static/icons/')
  } catch (err) {
    // If the URL is invalid, it's not a Gamma icon
  }

  return false
}

export const isUnsplashUrl = (url: string) => {
  // URLl is from Unsplash if it starts with the Unsplash host
  return url.startsWith(UNSPLASH_HOST)
}

export const isGiphyUrl = (url: string) => {
  return GIPHY_HOSTS.some((host) => url.startsWith(host))
}

export const isPictographicUrl = (url: string) => {
  return url.startsWith('https://cdn.pictographic.io/')
}

export const shouldUploadRemoteUrl = (url: string) => {
  return (
    url.startsWith('http') &&
    !isGammaCDNUrl(url) &&
    !isUnsplashUrl(url) &&
    !isGiphyUrl(url) &&
    !isPictographicUrl(url)
  )
}

export type ImageResizeParams = {
  width: number
  height: number
  quality: number

  // See https://docs.imgproxy.net/generating_the_url?id=processing-options
  resizing_type: 'fit' | 'fill' | 'fill-down' | 'force' | 'auto'
  blur?: number
  // This is a string conforming to the following shape:
  // `${cropWidth}:${cropHeight}:nowe:${xOffset}:${yOffset}`
  crop?: string
}

const DEFAULT_RESIZE_PARAMS: Partial<ImageResizeParams> = {
  quality: 80,
  resizing_type: 'fit',
} as const

/**
 * Cloudflare supports a different set of parameters than ImgProxy
 * In the event we need to fallback to Cloudflare, use this list
 * to filter out unsupported params, unless we have a mapping
 * defined below in ImgProxyToCloudflareMap
 */
const CloudflareSupportedParams: (keyof ImageResizeParams | 'onerror')[] = [
  'width',
  'height',
  'quality',
  'onerror',
]

/**
 * Map ImgProxy params to Cloudflare params so that
 * so that we can gracefully fallback to Cloudflare if needed
 */
const ImgProxyToCloudflareMap = {
  resizing_type: {
    cfKey: 'fit',
    valueMap: {
      fit: 'scale-down',
      fill: 'crop',
      'fill-down': 'scale-down',
      force: null,
      auto: null,
    },
  },
} as const

export const getImgProxyUrl = (
  url: string,
  params: Partial<ImageResizeParams>
) => {
  if (!params.width && !params.height && !params.crop) return url

  const urlParams = Object.entries({ ...DEFAULT_RESIZE_PARAMS, ...params })
    .map(([key, value]) => `${key}:${value}`)
    .filter(Boolean)
    .join('/')

  const result = `${config.IMAGE_WORKER_HOST}/resize/${urlParams}/${url}`

  return result
}

export const getUnsplashResizedUrl = (
  url: string,
  params: Partial<ImageResizeParams>
) => {
  if (!params.width) return url
  const urlObj = new URL(url)
  if (params.width) {
    urlObj.searchParams.set('w', String(params.width))
  }
  if (params.quality) {
    urlObj.searchParams.set('q', String(params.quality))
  }
  urlObj.searchParams.set('auto', 'format')
  return urlObj.toString()
}

const getCloudflareUrlParams = (params: Partial<ImageResizeParams>) => {
  const urlParams = Object.entries({
    ...DEFAULT_RESIZE_PARAMS,
    ...params,
    onerror: 'redirect', // Always redirect to the original image if there's an error
  })
    .map(([key, value]) => {
      // Check if the key needs to be mapped
      if (key in ImgProxyToCloudflareMap) {
        const { cfKey, valueMap } = ImgProxyToCloudflareMap[key]
        if (value in valueMap) {
          return `${cfKey}=${valueMap[value]}`
        }
        return null
      }
      // Otherwise, if the key isn't supported, return null
      if (!CloudflareSupportedParams.includes(key as any)) return null
      return `${key}=${value}`
    })
    .filter(Boolean)
    .join(',')
  return urlParams
}

// Replace all instances of Gamma CDN urls in the input (e.g. a background-image prop)
// with resized versions via Cloudflare
export const resizeGammaCDNUrls = (
  input: string,
  params: Partial<ImageResizeParams>
) => {
  if (!params.width && !params.height && !params.crop) return input

  if (featureFlags.get('imgProxy')) {
    return getImgProxyUrl(input, params)
  }

  const urlParams = getCloudflareUrlParams(params)
  GAMMA_CDN_HOSTNAMES.forEach((hostname) => {
    input = input.replace(
      hostname,
      // https://developers.cloudflare.com/images/image-resizing/url-format/
      `${hostname}/cdn-cgi/image/${urlParams}`
    )
  })
  return input
}

const RESIZE_THRESHOLD = 1.5 // Current / target width

export const THUMBNAIL_RESIZE_PARAMS = {
  width: 400,
  height: 400,
}

export const LOGO_RESIZE_PARAMS = {
  width: 300,
  height: 300,
}

export const isGifUrl = (url?: string | null) => {
  if (!url) {
    return false
  }
  try {
    const urlObj = new URL(url)
    return urlObj.pathname.endsWith('.gif')
  } catch (err) {
    return false
  }
}

// Works for any image URL, including ones not on our CDN
// If the URL is not on our CDN (e.g. from image search),
// it will proxy via our CDN
// Some images may error, so this should always be used with a fallback
export const resizeAndProxyImageUrl = (
  url: string,
  params: Partial<ImageResizeParams>,
  meta?: { width: number; height: number; frame_count?: number }
) => {
  // Skip SVGs - they are vectors so cant be resized or blurred
  if (isGammaIconUrl(url) || isSVG(url)) return url

  if (!params.width && !params.height) return url

  if (!params.blur) {
    // Don't resize animated GIFs
    if ((meta?.frame_count && meta.frame_count > 1) || isGifUrl(url)) return url

    // Resize image using Unsplash's API
    if (isUnsplashUrl(url)) {
      return getUnsplashResizedUrl(url, params)
    }
  }

  if (featureFlags.get('imgProxy')) {
    return getImgProxyUrl(url, params)
  }

  // If we're using Cloudflare, don't bother if it's small enough already
  if (
    (meta?.width &&
      params.width &&
      meta.width <= params.width * RESIZE_THRESHOLD) ||
    (meta?.height &&
      params.height &&
      meta.height <= params.height * RESIZE_THRESHOLD)
  ) {
    return url
  }

  const urlParams = getCloudflareUrlParams(params)
  const hostname =
    config.APPLICATION_ENVIRONMENT === 'production'
      ? GAMMA_CDN_HOSTNAME_PROD
      : GAMMA_CDN_HOSTNAME_STAGING
  return `${hostname}/cdn-cgi/image/${urlParams}/${url}`
}

// Converts one or more image URLs into a CSS background-image value
// Automatically removes duplicates and undefined/null values
export const backgroundImageFromUrls = (
  ...images: (string | null | undefined)[]
) => {
  return (
    '/**/' + // Empty comment, a workaround for https://github.com/chakra-ui/chakra-ui/issues/7548#issuecomment-1684034030
    uniq(images)
      .filter((url) => url) // Exclude blank/undefined
      .map((url: string) => `url("${url}")`)
      .join(', ')
  )
}

/**
 * See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
 * See: https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
 */
export function dataURLtoFile(dataurl, filename = 'image') {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const ext = mime.match(/image\/(.*)/)[1]

  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], `${filename}.${ext}`, { type: mime })
}

export function dataBlobToFile({
  blob,
  filename,
  ext,
}: {
  blob: Blob
  filename: string
  ext: string
}) {
  return new File([blob], `${filename}.${ext}`, { type: blob.type })
}

export const isDownloadableImageSrc = async (src: string): Promise<boolean> => {
  try {
    await downloadImage(src)
    return true
  } catch (e) {
    return false
  }
}

export const downloadImage = (src?: string) => {
  return new Promise((res, rej) => {
    if (!src) {
      res(null)
    } else {
      const img = new Image()
      img.src = src
      // Add cross-origin cors
      // This is important to ensure that CORS headers are
      // requested and cached for subsequent downloads of this image
      // See https://issues.chromium.org/issues/41025985
      img.crossOrigin = 'anonymous'
      img.onload = res
      img.onerror = rej
    }
  })
}

export function isImageMimeType(type: string): boolean {
  return /^image/i.test(type)
}

export const isValidImageString = (str: string) => {
  // TODO maybe we want this in something else
  if (/^data:image/.test(str)) {
    return true
  }

  // Verify that the image is a valid HTTPS url
  // AND
  // That the string is a valid CSS background-image value
  if (!/^https:\/\//.test(str)) return false

  // Set the string as a URL on a div and check if the browser allowed it to be set
  const el = document.createElement('div')
  el.style.backgroundImage = `url("${str}")`
  if (!el.style.backgroundImage) return false

  return true
}

/**
 * These two functions isHEICFileType and isHEICFileExtension use regex to determine
 * if something is HEIC / HEIF.
 *
 * NOTE: the template on transloadit will also need to be updated to filter for these
 * same values
 */
export function isHEICFileType(mimeType: string): boolean {
  return /heic|heif/i.test(mimeType)
}

export function isHEICFileExtension(filePath: string): boolean {
  return /\.heic|\.heif$/i.test(filePath)
}

export function svgToFile(svg: string): File {
  const type = 'image/svg+xml;charset=utf-8'
  const blob = new Blob(['<?xml version="1.0" standalone="no"?>\r\n', svg], {
    type,
  })
  const file = new File([blob], 'pasted.svg', {
    type,
  })
  return file
}

export const getImageDimensions = async (
  file: UppyFile['data']
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const url = URL.createObjectURL(file)
    const img = new Image()
    img.onload = function () {
      URL.revokeObjectURL(img.src)
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.src = url
  })
}

// Throw if file is too big
export const validateImageSize = async (file: UppyFile) => {
  const res = await getImageDimensions(file.data)
  if (res.width * res.height > IMGPROXY_MAX_PIXELS) {
    toast({
      id: 'image-too-big',
      title: <Trans>Image exceeds maximum dimensions</Trans>,
      description: (
        <Trans>
          The image you uploaded is larger than the maximum allowed dimensions.
          Please ensure your image is smaller than {IMGPROXY_MAX_MEGAPIXELS}{' '}
          megapixels (e.g. {IMGPROXY_MAX_WIDTH_PIXELS}x
          {IMGPROXY_MAX_HEIGHT_PIXELS}) and try again.
        </Trans>
      ),
      status: 'error',
      duration: null,
      isClosable: true,
      position: 'top',
    })
    throw new Error('IMAGE_TOO_BIG')
  }
  return
}
