import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

export type ZendeskState = {
  isOpen: boolean
  jwt: string | null
}

const initialState: ZendeskState = {
  isOpen: false,
  jwt: null,
}

const ZendeskSlice = createSlice({
  name: 'Zendesk',
  initialState,
  reducers: {
    reset: () => initialState,
    setZendeskJWT(state: ZendeskState, action: PayloadAction<string>) {
      state.jwt = action.payload
    },
    setIsZendeskOpen(
      state: ZendeskState,
      action: PayloadAction<{
        isOpen: boolean
      }>
    ) {
      const { isOpen } = action.payload
      state.isOpen = isOpen
    },
  },
})

export const { setZendeskJWT, setIsZendeskOpen } = ZendeskSlice.actions

type SliceState = Pick<RootState, 'Zendesk'>

// Selectors
export const selectZendeskJWT = (state: SliceState): string | null => {
  return state.Zendesk.jwt
}

export const selectIsZendeskOpen = (state: SliceState): boolean => {
  return state.Zendesk.isOpen
}

// Reducer
export const reducer = ZendeskSlice.reducer
