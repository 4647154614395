import { Organization } from 'modules/api/generated/graphql'
import { fetchJWT } from 'modules/auth/utils'
import { featureFlags } from 'modules/featureFlags'
import { getProductForWorkspace } from 'modules/monetization/utils'
import { getStore } from 'modules/redux'

import { GraphqlUser } from '../user/context/UserContext'
import { selectZendeskJWT, setZendeskJWT } from './reducer'

const getJWT = async () => {
  const store = getStore()
  const existingJWT = selectZendeskJWT(store.getState())
  if (existingJWT) {
    return existingJWT
  }

  const newJWT = await fetchJWT('zendesk')
  if (newJWT) {
    store.dispatch(setZendeskJWT(newJWT))
  }

  return newJWT
}

export const loginZendeskUser = async () => {
  const [jwt] = await Promise.all([getJWT(), featureFlags.initializePromise])
  const Zendesk = window.zE

  if (!Zendesk || !jwt || !featureFlags.get('zendesk')) {
    return
  }

  Zendesk('messenger', 'loginUser', (cb) => {
    console.log('Zendesk loginUser request:', jwt)
    const res = cb(jwt)
    console.log('Zendesk loginUser response:', res)
  })
}

export const updateZendeskUser = async ({
  user,
  currentWorkspace,
}: {
  user: GraphqlUser
  currentWorkspace?: Organization
}) => {
  await featureFlags.initializePromise

  const Zendesk = window.zE

  if (!Zendesk || !featureFlags.get('zendesk')) {
    return
  }

  Zendesk('messenger:set', 'conversationFields', [
    // https://gamma-app.zendesk.com/admin/objects-rules/tickets/ticket-fields
    {
      id: '30707940954132', // User ID
      value: user?.id,
    },
    {
      id: '31793648423828', // Display Name
      value: user?.displayName,
    },
    {
      id: '30707962452756', // Org ID
      value: String(currentWorkspace?.id),
    },
    {
      id: '30707981722132', // Stripe Plan
      value: String(getProductForWorkspace(currentWorkspace)),
    },
  ])
}
